import React from 'react';

const TabSelector = ({ tabs, activeTab, setActiveTab }) => {
  const containerStyle = {
    display: 'flex',
    backgroundColor: tabs[activeTab].bgColor,
    borderRadius: '20px',
    maxWidth: '600px',
    transition: 'background-color 0.3s',
    margin: 'auto'
  };

  const buttonStyle = (index) => ({
    flex: 1,
    padding: '10px',
    border: 'none',
    borderRadius: '15px',
    cursor: 'pointer',
    backgroundColor: activeTab === index ? tabs[index].activeColor : 'transparent',
    color: activeTab === index ? 'white' : 'black',
    transition: 'background-color 0.3s, color 0.3s',
    margin: '0 2px',
    fontSize: '14px',
    fontWeight: 'bold',
  });

  return (
    <div style={containerStyle}>
      {tabs.map((tab, index) => (
        <button
          key={index}
          style={buttonStyle(index)}
          onClick={() => setActiveTab(index)}
        >
          {tab.name}
        </button>
      ))}
    </div>
  );
};

export default TabSelector;