import React, { useLayoutEffect, useState } from 'react';
import styles from '../../styles/redeem/redeemForm.module.css';
import { subscribeRedeemCoins } from '../../ApiCalls/Redeem/api_v1_redeem';
import check from "../../images/v1/redeem/check.svg";

function SubscribeConsentForm() {
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [btnValue, setBtnValue] = useState("Subscribe");
    const [subscribed, setSubscribed] = useState(false);
    const [subscribeFailed, setsubscribeFailed] = useState(false);
    const [subscribeFailedMsg, setsubscribeFailedMsg] = useState(false);
    useLayoutEffect(() => {
        document.body.style.backgroundColor = "#f8b600";
        document.querySelector("meta[name='theme-color']").setAttribute("content", "#f8b600");
    }, []);
    async function handleSubscribe() {
        setBtnValue("Please Wait");
        setBtnDisabled(true);
        try {

            const res = await subscribeRedeemCoins();
            const resp = res.data.data;
            if (resp.suv === 1) {
                setSubscribed(true)
            } else {
                setSubscribed(false)
            }
        } catch (e) {
            setsubscribeFailed(true);
            setsubscribeFailedMsg(e.response.data.message);
            setTimeout(() => {   // ***
                setsubscribeFailed(false);
                setsubscribeFailedMsg(null);
                setBtnValue("Subscribe");
                setBtnDisabled(false);
            }, 15000);
        }

    }

    function handleRedirectRedeem() {
        window.location.reload();
    }
    return (
        <main>

            <section className={`${styles.insufficentCoins} ` + (subscribeFailed ? `${styles.show}` : `${styles.hidden}`)}>
                <p style={{ padding: "5px" }}>{subscribeFailedMsg}</p>
            </section>
            {!subscribed ?
                <section className={styles.formSection}>
                    <h3 className={styles.formSectionh3}>Dear User!</h3>
                    <p className={styles.formSectionp1}>You're on a free trial.<br /> Click on Subscribe to unlock coin redemption</p>
                    <button type="button" className={styles.RedeemFormBtn} disabled={btnDisabled} onClick={handleSubscribe}>{btnValue}</button>
                </section>
                :
                <section className={styles.formSection} style={{ alignItems: "center" }}>
                    <img src={check} alt="loading ..." style={{ width: "100px", height: "100px", minHeight: "100px" }} />
                    <h3 className={styles.formSectionh3}>Congratulations!</h3>
                    <p className={styles.formSectionp1}>You can now redeem Ding coins.</p>
                    <button type="button" className={styles.RedeemFormBtn} onClick={handleRedirectRedeem}>Back To Redeem</button>
                </section>
            }

        </main>
    )
}

export default SubscribeConsentForm;