import React, { useEffect, useState } from 'react';
import AvatarBuilder, { AvatarProperties } from '../avatar/components/AvatarProperties';
import { getStreaksInfo } from '../../ApiCalls/Rewards/api_v1_rewards';
import ding_token from '../../images/v1/home/ding_token.png';
import { useNavigate } from 'react-router-dom';
import { loadAvatar } from '../../ApiCalls/Profile/api_v1_profile';
import backIcon from '../../images/v1/icons/ion_chevron-back.svg'; 
import { useCoinBalance } from './CoinContext';
import fireIcon from '../../images/v1/rewards/streak_icon.png'

const AppBar = ({ title }) => {
    const { coinBalance, isLoading } = useCoinBalance();
    const [dailyStreak, setDailyStreak] = useState(0);
    const navigate = useNavigate();
    const [userOptions, setUserOptions] = useState(AvatarProperties.defaultProperties);

    useEffect(() => {
        const loadSavedOptions = async () => {
            try {
                const response = await loadAvatar();
                if (response && response.data && response.data.avatar) {
                    const avatarData = response.data.avatar;
                    const relevantOptions = {
                        style: avatarData.style || "Transparent",
                        topType: avatarData.topType || "Flat",
                        accessoriesType: avatarData.accessoriesType || "Nothing",
                        hairColor: avatarData.hairColor || "Black",
                        facialHairType: avatarData.facialHairType || "Nothing",
                        facialHairColor: avatarData.facialHairColor || "BlondeGolden",
                        clotheType: avatarData.clotheType || "Crew Neck Tee",
                        clotheColor: avatarData.clotheColor || "Blue01",
                        eyeType: avatarData.eyeType || "Default",
                        eyebrowType: avatarData.eyebrowType || "Default",
                        mouthType: avatarData.mouthType || "Default",
                        skinColor: avatarData.skinColor || "White"
                    };
                    setUserOptions(relevantOptions);
                }
            } catch (error) {
                console.error('Error loading saved avatar options:', error);
            }
        };
        loadSavedOptions();
        fetchDailyStreak();
    }, []);

    const fetchDailyStreak = async () => {
        try {
            const response = await getStreaksInfo();
            setDailyStreak(response.data.data.daily_streak);
        } catch (error) {
            console.error('Failed to fetch daily streak:', error);
        }
    };

    const styles = `
    .app-bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #1A1A1A;
      color: white;
      padding: 10px 20px;
      font-family: Arial, sans-serif;
      position: sticky;
    }

    .app-bar-left {
      display: flex;
      align-items: center;
    }

    .back-button {
      background: none;
      border: none;
      cursor: pointer;
      padding: 0; /* Remove padding */
    }

    .back-icon {
      width: 24px;
      height: 24px;
    }

    .title {
      font-size: 18px;
      font-weight: bold;
    }

    .app-bar-right {
      display: flex;
      align-items: center;
    }

    .coin-container, .streak-container {
      display: flex;
      align-items: center;
      margin-right: 15px;
    }

    .coin-icon, .streak-icon {
      width: 24px;
      height: 24px;
      margin-right: 5px;
    }

    .coin-count, .streak-count {
      font-size: 16px;
    }

    .avatar-wrapper {
      width: 40px;
      height: 40px;
      overflow: hidden;
      border-radius: 50%;
      background: beige;
      cursor: pointer; /* Add cursor pointer to indicate clickable */
    }

    .avatar-container {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      transform-origin: center;
      overflow: hidden;
    }

    .avatar-container > div {
      width: 264px;
      height: 280px;
    }

    .avatar-container svg {
      width: 38px;
    }
  `;

  return (
    <>
        <style>{styles}</style>
        <div className="app-bar">
            <div className="app-bar-left">
                <button className="back-button" onClick={() => navigate(-1)}>
                    <img src={backIcon} alt="Back" className="back-icon" />
                </button>
                <span className="title">{title}</span>
            </div>
            <div className="app-bar-right">
                <div className="coin-container">
                    <img src={ding_token} alt="Ding token" className="coin-icon" />
                    <span className="coin-count">
                        {isLoading ? 'Loading...' : coinBalance}
                    </span>
                </div>
                <div className="streak-container">
                <img className='streak-icon' src={fireIcon} />
                    <span className="streak-count">{dailyStreak}</span>
                </div>
                <div className="avatar-wrapper" onClick={() => navigate('/profile')}>
                    <div className="avatar-container">
                        <AvatarBuilder properties={userOptions} />
                    </div>
                </div>
            </div>
        </div>
    </>
);
};

export default AppBar;