import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';
import styles from '../../styles/profile/profile.module.css';
import ding_token from '../../images/v1/home/ding_token.png';
import { timeStats } from '../../ApiCalls/TimeRecorder/api_v1_time_recorder';
import { getUser, setInterests } from "../../ApiCalls/Profile/api_v1_profile";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import Cookies from 'universal-cookie';
import leftArrow from "../../images/v1/icons/left_arrow_white.png";
import threeDots from "../../images/v1/icons/three_dots.svg"
import logoutImg from "../../images/v1/profile/logout.png";
// import profileAltImg from "../../images/v1/profile/profile_alt.png";
import editImg from "../../images/v1/profile/edit.png";
// import SfxMainAudio from "../AutoPlayAudio/SfxMainAudio";
import { BarChart } from '@mui/x-charts/BarChart';

function Profile() {
    const cookies = new Cookies();
    let tel = useRef();
    let phn = useRef()
    let uname = useRef();
    // let coins = useRef();
    // let videosWatched = useRef();
    // let quizesPlayed = useRef();
    // let correctAnswers = useRef();
    // let rewardsRedeemed = useRef();
    let [showSideBar, setShowSideBar] = useState(false);
    // let [mon, setMon] = useState("0px");
    // let [tue, setTue] = useState("0px");
    // let [wed, setWed] = useState("0px");
    // let [thu, setThu] = useState("0px");
    // let [fri, setFri] = useState("0px");
    // let [sat, setSat] = useState("0px");
    // let [sun, setSun] = useState("0px");
    // let monTime = useRef("0");
    // let tueTime = useRef("0");
    // let wedTime = useRef("0");
    // let thuTime = useRef("0");
    // let friTime = useRef("0");
    // let satTime = useRef("0");
    // let sunTime = useRef("0");
    // const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    const [daysOfWeek, setdaysOfWeek] = useState(['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']);
    let [timeSpent, setTimeSpent] = useState([])
    const interestAllRef = useRef(0);
    const interestCurriculumRef = useRef(0);
    const interestEdutainmentRef = useRef(0);
    const interestInfotainmentRef = useRef(0);
    const showUnsubscribe = useRef(false);
    const storedAvatar = localStorage.getItem("avatar");
    const [avatar, setAvatar] = useState(storedAvatar !== null ? storedAvatar : ding_token);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([{
        allVideos: false,
        curriculumVideos: false,
        edutainmentVideos: false,
        infotainmentVideos: false
    }
    ]);
    const [userData, setUserData] = useState({
        coins: 0,
        watchedVideos: 0,
        quizPlayed: 0,
        correctAnswers: '0',
        redeemed: 0
    });
    const [isVisible, setIsVisible] = useState(false);
    const isLoading = useRef(0);
    let timePrompt = useRef();
    let navigate = useNavigate();


    useLayoutEffect(() => {
        document.body.style.backgroundColor = "#191919";
        document.querySelector("meta[name='theme-color']").setAttribute("content", "#191919");
        document.title = "Profile | Ding";
    }, []);
    useEffect(() => {
        try {
            const accessToken = localStorage.getItem('token');
            const decoded = jwt_decode(accessToken);
            const msisdn = String(decoded.userInfo['msisdn']);
            if (msisdn.substring(0, 2) === "92") {
                showUnsubscribe.current = true;
            } else {
                showUnsubscribe.current = false;
            }

        } catch (error) {
            showUnsubscribe.current = false;
        }
        getUser().then(response => {
            const accessToken = response.headers['access_token'];
            if (accessToken != null) {
                localStorage.setItem('token', accessToken);
            }

            // const valuesArray = JSON.parse(response.data);
            // let result = text.slice(0, 5);
            let data = response.data.data;
            // console.log(data);
            let name = data.name;
            let avatarUrl = data.avatar_url;
            let avatarName = data.avatar_name;
            if (avatarName != "user") {
                setAvatar(avatarUrl);
                localStorage.setItem("avatar", avatarUrl);
            }
            if (name === null) {
                let fullPhone = data.msisdn;
                let firstPart = fullPhone.slice(2, 5);
                let secondPart = fullPhone.slice(5);
                tel.current.innerHTML = "+92 " + firstPart + " " + secondPart;
                uname.current.innerHTML = "+92 " + firstPart + " " + secondPart;
            } else {
                tel.current.innerHTML = name;
                uname.current.innerHTML = name;
                let fullPhone = data.msisdn;
                let firstPart = fullPhone.slice(2, 5);
                let secondPart = fullPhone.slice(5);
                phn.current.innerHTML = "+92 " + firstPart + " " + secondPart;
            }

            // coins.current.innerHTML = data.coins;
            // videosWatched.current.innerHTML = data.watchedVideos;
            // quizesPlayed.current.innerHTML = data.quizPlayed;
            // correctAnswers.current.innerHTML = data.corectAns + "%";
            // rewardsRedeemed.current.innerHTML = data.redeemed;
            setUserData({
                coins: data.coins,
                watchedVideos: data.watchedVideos,
                quizPlayed: data.quizPlayed,
                correctAnswers: data.corectAns,
                redeemed: data.redeemed
            });
            // Iterate over the checkboxes
            // Update the state with the new values
            setSelectedCheckboxes([{
                allVideos: data.interests.allVideos === 1,
                curriculumVideos: data.interests.curriculumVideos === 1,
                edutainmentVideos: data.interests.edutainmentVideos === 1,
                infotainmentVideos: data.interests.infotainmentVideos === 1
            }]);
        })
            .catch(error => {
                // console.log(error);
                // console.log(error.response.status,error.response.data.message);
            })

        timeStats().then(response => {
            const accessToken = response.headers['access_token'];
            if (accessToken != null) {
                localStorage.setItem('token', accessToken);
            }
            if (response.data.message == 'ok' && response.status == 200) {
                let resData = response.data.data;
                var timeS = []
                setdaysOfWeek(resData.map(item => item.day));
                resData.forEach(element => {
                    if (element.time !== null) {
                        // setTimeSpent(timeSpent.push(element.time))
                        timeS.push(element.time)
                    }
                    // if (element.day === 'Mon') {
                    //     setMon(element.height + "px");
                    //     monTime.current = element.time;
                    // }
                    // if (element.day === 'Tue') {
                    //     setTue(element.height + "px");
                    //     tueTime.current = element.time;
                    // }
                    // if (element.day === 'Wed') {
                    //     setWed(element.height + "px");
                    //     wedTime.current = element.time;
                    // }
                    // if (element.day === 'Thu') {
                    //     setThu(element.height + "px");
                    //     thuTime.current = element.time;
                    // }
                    // if (element.day === 'Fri') {
                    //     setFri(element.height + "px");
                    //     friTime.current = element.time;
                    // }
                    // if (element.day === 'Sat') {
                    //     setSat(element.height + "px");
                    //     satTime.current = element.time;
                    // }
                    // if (element.day === 'Sun') {
                    //     setSun(element.height + "px");
                    //     sunTime.current = element.time;
                    // }
                    // console.log(element);
                });
                setTimeSpent(timeS);
                // console.log(resData);
            }

        }).catch(error => {
            console.log(error);
        })
    }, [])
    // function showMinutes(day) {
    //     if (day == "mon") {
    //         timePrompt.current.innerHTML = monTime.current + " minutes";
    //     }
    //     if (day == "tue") {
    //         timePrompt.current.innerHTML = tueTime.current + " minutes";
    //     }
    //     if (day == "wed") {
    //         timePrompt.current.innerHTML = wedTime.current + " minutes";
    //     }
    //     if (day == "thu") {
    //         timePrompt.current.innerHTML = thuTime.current + " minutes";
    //     }
    //     if (day == "fri") {
    //         timePrompt.current.innerHTML = friTime.current + " minutes";
    //     }
    //     if (day == "sat") {
    //         timePrompt.current.innerHTML = satTime.current + " minutes";
    //     }
    //     if (day == "sun") {
    //         timePrompt.current.innerHTML = sunTime.current + " minutes";
    //     }
    // }
    // function handleClick() {
    //     navigate('/home');
    // }
    function editProfile() {
        navigate('/edit-profile');
    }
    useEffect(() => {
        if (isLoading.current === 0) {
            return;
        }
        let debounceTimer;

        // if (selectedCheckboxes.length > 0) {
        debounceTimer = setTimeout(() => {
            // Make the API call with the selected checkboxes
            // console.log('API call triggered with:', selectedCheckboxes);
            // setIsLoading(true);
            // Create the payload object
            // const payload = selectedCheckboxes[0];


            setInterests(selectedCheckboxes[0])
                .then(function (res) {
                    if (res.status === 200) {
                        const data = res.data.data;
                        let check = false;
                        const updatedCheckbox = { ...selectedCheckboxes[0] };

                        for (const key in data) {
                            if (data.hasOwnProperty(key)) {
                                const elem = data[key];
                                updatedCheckbox[key] = elem;

                            }
                        }
                        setSelectedCheckboxes([updatedCheckbox]); // Update the state with the modified object
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });

            // Simulating API call delay (remove this setTimeout in your actual code)
            setIsVisible(true);

            // Hide the div with fade-out effect after 1 second
            const timeout = setTimeout(() => {
                setIsVisible(false);
            }, 2000);

            // Clear the timeout when the component unmounts
            // setTimeout(() => {
            //     // API call response received
            //     console.log('API call completed');
            //     // setIsLoading(false);
            // }, 2000);
            return () => clearTimeout(timeout);
        }, 1000);
        // }

        // Clear the debounce timer when the component is unmounted or selectedCheckboxes change
        return () => {
            clearTimeout(debounceTimer);
        };
    }, [isLoading.current]);
    // function handleCheckboxChange(event){
    //     const checkboxValue = event.target.value;
    //     const isChecked = event.target.checked;
    //
    //     if (isChecked) {
    //         setSelectedCheckboxes((prevCheckboxes) => [...prevCheckboxes, checkboxValue]);
    //     } else {
    //         setSelectedCheckboxes((prevCheckboxes) => prevCheckboxes.filter((item) => item !== checkboxValue));
    //     }
    //
    // }
    function handleCheckboxChange(event) {
        const checkboxValue = event.target.value;
        const isChecked = event.target.checked;
        if (checkboxValue === 'allVideos') {
            //     let  isChecked = true;
            const updatedCheckbox = { ...selectedCheckboxes[0] }; // Create a copy of the existing state object
            let trueFalse = true;
            if (isChecked === false)
                trueFalse = false;
            for (const key in updatedCheckbox) {

                updatedCheckbox[key] = trueFalse; // Update the specific checkbox state in the copied object
            }
            setSelectedCheckboxes([updatedCheckbox]); // Update the state with the modified object
            isLoading.current++;
            return;
        } else {

            // Update the state based on the checkbox value
            // setSelectedCheckboxes((prevCheckboxes) => ({
            //     ...prevCheckboxes,
            //     [checkboxValue]: isChecked,
            // }));
            setSelectedCheckboxes(prevState => {
                const updatedCheckbox = {
                    ...prevState[0],
                    [checkboxValue]: isChecked
                };

                return [updatedCheckbox];
            });
        }
        isLoading.current++;
        // Rest of your logic
    }

    function handleLogout() {
        localStorage.removeItem('token');
        localStorage.removeItem('ctx');
        localStorage.removeItem('dx');
        localStorage.removeItem('time');
        cookies.remove('uid');
        cookies.remove('rmsid');
        const nextYear = new Date();
        const currentD = new Date();
        nextYear.setFullYear(currentD.getFullYear() + 1);
        cookies.set('uid_exp', 1, { path: '/', expires: nextYear });
        // return navigate('/login');
        const redirectURL = process.env.REACT_APP_SESSION_URL + "/?logout=1";
        window.location.href = redirectURL;
        return;
    }
    function navigateHome() {
        return navigate("/home/landing");
    }
    function toggleSideBar() {
        setShowSideBar(!showSideBar);
    }
    return (
        <>
            <main className={styles.main}>
                <section className={styles.timeSpentSection}>
                    <div className={styles.barcontainer}>
                        <h3 className={`text-theme fs-15 ${styles.timeSpentText}`}>Time spent</h3>
                        <h3 className={`fs-15 ${styles.perdayText}`}>per day</h3>
                        <div className={styles.barcontainerheader}>
                            <p ref={timePrompt} className={styles.timetextPrompt}></p>
                        </div>
                        <BarChart
                            sx={{ color: 'white', ".MuiChartsAxis-root .MuiChartsAxis-line": { stroke: 'white' } }}
                            xAxis={[{ scaleType: 'band', data: daysOfWeek, tickLabelStyle: { fill: 'white', borderColor: 'white' } }]}
                            yAxis={[{ tickLabelStyle: { fill: 'white' } }]}
                            series={[{ data: timeSpent, color: '#FFCA0A' }]}
                            width={undefined}
                            height={250}
                        />
                        {/* <div className={styles.barsWrap}>
                            <div className={styles.bar} style={{ height: sun }} onClick={() => showMinutes("sun")}>

                                <div className={styles.barlabel}>
                                    sun
                                </div>
                            </div>
                            <div className={styles.bar} style={{ height: mon }} onClick={() => showMinutes("mon")}>

                                <div className={styles.barlabel}>
                                    mon
                                </div>
                            </div>
                            <div className={styles.bar} style={{ height: tue }} onClick={() => showMinutes("tue")}>

                                <div className={styles.barlabel}>
                                    tue
                                </div>
                            </div>
                            <div className={styles.bar} style={{ height: wed }} onClick={() => showMinutes("wed")}>

                                <div className={styles.barlabel}>
                                    wed </div>
                            </div>
                            <div className={styles.bar} style={{ height: thu }} onClick={() => showMinutes("thu")}>

                                <div className={styles.barlabel}>
                                    thu
                                </div>
                            </div>
                            <div className={styles.bar} style={{ height: fri }} onClick={() => showMinutes("fri")}>

                                <div className={styles.barlabel}>
                                    fri
                                </div>
                            </div>
                            <div className={styles.bar} style={{ height: sat }} onClick={() => showMinutes("sat")}>

                                <div className={styles.barlabel}>
                                    sat
                                </div>
                            </div>
                        </div> */}
                    </div>
                </section>
                <div className={`mt-3 ml-3 w-full d-flex align-items-center justify-content-between`} >
                </div>
                <div className={`mt-3 ml-3 w-full d-flex align-items-center justify-content-between`} >
                </div>
                <section className={styles.performanceSection}>
                    <div className={styles.detailsWrapper}>
                        <div className={styles.performanceDetail}>
                            <div>
                                <p>videos watched</p>
                            </div>
                            <div className={styles.performanceValue}>
                                <p className='text-theme fw-600'>{userData.watchedVideos}</p>
                            </div>
                        </div>
                        <div className={styles.performanceDetail}>
                            <div>
                                <p>quizes played</p>
                            </div>
                            <div className={styles.performanceValue}>
                                <p className='text-theme fw-600'>{userData.quizPlayed}</p>
                            </div>
                        </div>
                        <div className={styles.performanceDetail}>
                            <div>
                                <p>correct answers</p>
                            </div>
                            <div className={styles.performanceValue}>
                                <p className='text-theme fw-600' >{userData.correctAnswers}%</p>
                            </div>
                        </div>
                        <div className={styles.performanceDetail}>
                            <div>
                                <p>rewards redeemed</p>
                            </div>
                            <div className={styles.performanceValue}>
                                <p className='text-theme fw-600' >{userData.redeemed}</p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className={styles.timeSpentSection}>
                    <div className={styles.detailsWrapper}>
                        {/* {isVisible && */}
                        <div className={`${styles.preferencesSaved} ${isVisible ? styles.z1 : styles.z0}`}>
                            <h3>Saved</h3>
                        </div>
                        {/* } */}
                        <h6 className="text-white text-theme">Select your interests</h6>
                        <div className={styles.performanceDetail}>
                            <div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="allVideos" id="all" checked={selectedCheckboxes[0].allVideos} ref={interestAllRef} onChange={handleCheckboxChange} />
                                    <label className="form-check-label text-white" htmlFor="all">
                                        All
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className={styles.performanceDetail}>
                            <div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="curriculumVideos" id="curriculumVideos" ref={interestCurriculumRef} onChange={handleCheckboxChange} checked={selectedCheckboxes[0].curriculumVideos} />
                                    <label className="form-check-label text-white" htmlFor="curriculumVideos">
                                        Curriculum Videos
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className={styles.performanceDetail}>
                            <div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="edutainmentVideos" id="edutainmentVideos" ref={interestEdutainmentRef} onChange={handleCheckboxChange} checked={selectedCheckboxes[0].edutainmentVideos} />
                                    <label className="form-check-label text-white" htmlFor="edutainmentVideos">
                                        Edutainment Videos
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className={styles.performanceDetail}>
                            <div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="infotainmentVideos" id="infotainmentVideos" ref={interestInfotainmentRef} onChange={handleCheckboxChange} checked={selectedCheckboxes[0].infotainmentVideos} />
                                    <label className="form-check-label text-white" htmlFor="infotainmentVideos">
                                        Infotainment Videos
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* {showUnsubscribe.current &&
                    <section className={styles.buttonSection}>
                        <button type='button' className={styles.backToVideos} onClick={handleLogout}>Logout</button>
                    </section>
                } */}
            </main>
        </>
    )
}

export default React.memo(Profile);