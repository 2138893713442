export function otpInput(p, s) {
  var showcode = s;
  var pincode = p;
  if (showcode == 1) {
    var otp_length = 4;
  } else {
    var otp_length = 4;
  }

  const element = document.getElementById('OTPInput');
  /*  This is for switching back and forth the input box for user experience */
  const inputs = document.querySelectorAll('#OTPInput > *[id]');

  for (let i = 0; i < inputs.length; i++) {
    if (showcode == 1) { //jazz user
      var N = pincode;
      var arr = N.toString().split('').map(Number);
      inputs[i].setAttribute("value", arr[i]);
    }
    inputs[i].addEventListener('keydown', function (event) {
      // handle chars start from here
      var theEvent = event || window.event;

      // Handle paste
      //   if (theEvent.type === 'paste') {
      //      event.key = event.clipboardData.getData('text/plain');
      //   } 
      // handle chars end here
      if (event.which == 13 || event.keyCode == 13) {

        return false; // returning false will prevent the event from bubbling up.
      } else
        if (event.key === "Backspace") {

          if (inputs[i].value == '') {
            if (i != 0) {
              inputs[i - 1].select();
            }
          } else {
            inputs[i].value = '';
          }

        } else if (event.key === "ArrowLeft" && i !== 0) {
          inputs[i - 1].select();
        } else if (event.key === "ArrowRight" && i !== inputs.length - 1) {
          inputs[i + 1].select();
        } else if (event.key != "ArrowLeft" && event.key != "ArrowRight") {


          //   inputs[i].setAttribute("type", "tel");
          //   inputs[i].value = ''; // Bug Fix: allow user to change a random otp digit after pressing it
          // setTimeout(function() {
          //   inputs[i].setAttribute("type", "password");
          // }, 1000); // Hides the text after 1 sec
        }
    });
    inputs[i].addEventListener('input', function () {
      inputs[i].value = inputs[i].value.toUpperCase(); // Converts to Upper case. Remove .toUpperCase() if conversion isnt required.
      if (i === inputs.length - 1 && inputs[i].value !== '') {
        return true;
      } else if (inputs[i].value !== '') {
        inputs[i + 1].select();
      }
    });

  }
  /*  This is to get the value on pressing the submit button
    *   In this example, I used a hidden input box to store the otp after compiling data from each input fields
    *   This hidden input will have a name attribute and all other single character fields won't have a name attribute
    *   This is to ensure that only this hidden input field will be submitted when you submit the form */

  document.getElementById('btnsubmit').addEventListener("click", function () {
    const inputs = document.querySelectorAll('#OTPInput > *[id]');
    let compiledOtp = '';
    for (let i = 0; i < inputs.length; i++) {
      compiledOtp += inputs[i].value;
    }
    document.getElementById('otp').value = compiledOtp;
    //   handleSubmit(compiledOtp);
    return true;
  });

}

export function generalErrorMessage(errorInfo, setErrorInfo, error_Message = "") {
  if (error_Message != "") {
    setErrorInfo({ ...errorInfo, errorGeneral: 1, generalErrorMessage: error_Message });
  } else {
    setErrorInfo({ ...errorInfo, errorGeneral: 1 });
  }
  setTimeout(
    () => setErrorInfo({ ...errorInfo, errorGeneral: 0 }),
    5000
  );
}
