import React from 'react';
import './SpinRedeemDialog.css';
import redeemSpinIcon from '../../images/v1/rewards/ic_redeem_spin.png'
const SpinRedeemDialog = ({ spinAmount, timeRemaining, onClose, onRedeem }) => {
    return (
        <div className="spin-redeem-overlay">
            <div className="spin-redeem-dialog">
                <button className="close-button" onClick={onClose}>X</button>
                <div className="dialog-content">
                    <img src={redeemSpinIcon} alt="Redeem Spin" className="redeem-icon" />
                    <h2 className="spin-amount-msg">Spend {spinAmount} Coins to Play Now!</h2>
                    <p className="reward-text">
                        You can play for free after {timeRemaining} or spend coins to play now.
                    </p>
                    <button className="redeem-button" onClick={onRedeem}>
                        Play Again
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SpinRedeemDialog;