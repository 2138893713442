import React from 'react';
import Lottie from 'react-lottie';
import './RewardPopup.css';
import animationData from '../../animations/anim_celeb.json'; 
import congratsIcon from '../../images/v1/rewards/ic_congrats.png'
const RewardPopup = ({ reward, onClose }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div className="reward-popup-overlay">
      <div className="reward-popup-container">
        <div className="lottie-animation">
          <Lottie options={defaultOptions} height={300} width={300} />
        </div>
        <div className="reward-content">
          <img src={congratsIcon} alt="Congratulations" width="auto" height={100} className="congrats-icon" />
          <h2 className="congratulations-text">Congratulations</h2>
          <p className="reward-text">You've won {reward} coins!</p>
          <button className="claim-button" onClick={onClose}>
            Claim
          </button>
        </div>
      </div>
    </div>
  );
};

export default RewardPopup;