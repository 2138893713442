import  axiosClient  from "../axiosClient";

export function statisticsRecorder(data){
    let bearerTokenHeader = localStorage.getItem('token');
    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${bearerTokenHeader}`;
    return axiosClient.post('/stats',JSON.stringify(data));
}

export function timeStats() {
    let bearerTokenHeader = localStorage.getItem('token');
    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${bearerTokenHeader}`;
    return axiosClient.get('/stats/user');
}
