import React, { useRef } from 'react';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { getVerifyUser, getVideos, videoWatched, getUidVideo } from "../../ApiCalls/Home/api_v1_home";
import styles from '../../styles/home/home.module.css';
import FirstVisitDemoAnimation from "../Animations/FirstVisitDemoAnimationV1";
import SidebarBottom from "./SidebarBottom";
import Share from "./Share";
import SearchPopup from "./SearchPopup";
import LinkCopied from "./LinkCopied"
import LogoAnimation from "../Animations/LogoAnimation";
import play from "../../images/v1/icons/play.svg";
import Loader from '../Animations/Loader';
import PwaNotification from '../InstallPwa/PwaNotification';
import BufferingAnimation from '../Animations/BufferingAnimation';
import Header from '../header/Header';
import { registerStreak } from '../../ApiCalls/Rewards/api_v1_rewards';
export default function Home({ showSplash }) {
    let navigate = useNavigate();
    const location = useLocation();
    const [isloading, setIsLoading] = useState(showSplash);
    const [isBuffering, setIsBuffering] = useState(false);
    const [isPlaying, setisPlaying] = useState(false);
    const [isPwaInstallShown, setIsPwaInstallShown] = useState(false);
    // const [vKey, setvKey] = useState("item_");
    // const vKeyRef = useRef(0);
    // const [canShowInstallPrompt, setCanShowInstallPrompt] = useState(false);
    const currentVideoListIndex = useRef({
        currIndex: 0,
        vid: null,
        vkey: 0,
        like: 0,
    });
    const [currentVideStateObj, setCurrentVideStateObj] = useState({
        vID: 0,
        vKey: "item_",
        like: 0,
    });

    useEffect(() => {
        const doLogin = async () => {
            try {
                const response = await registerStreak();
                if(response.status == 200) {
                    // console.log("Logge d In")
                }
            } catch (error) {
                console.error('Failed to register login:', error);
            }
        };
        doLogin();
    }, []);

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.hidden) {
                // Tab is inactive
                if (isPlaying) {
                    setisPlaying(false);
                    const currentVideo = document.getElementById(currentVideoListIndex.current.vkey);
                    if (currentVideo) {
                        currentVideo.pause();
                    }
                }
            } else {
                setisPlaying(true);
                const currentVideo = document.getElementById(currentVideoListIndex.current.vkey);
                if (currentVideo) {
                    currentVideo.play();
                }
            }
        };

        document.addEventListener("visibilitychange", handleVisibilityChange);

        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
    }, [isPlaying]);

    // const [vID, setvID] = useState(0);
    // const [vidLike, setLike] = useState(0);
    const [copied, setCopied] = useState(0);
    const showShare = useRef(false);
    const showSearch = useRef(false);
    const [showDemo, setShowDemo] = useState(false);
    const [LeftMove, setLeftMove] = useState("100%");
    const [showTrial, setShowTrial] = useState(false);
    const getVideosOnceLoad = useRef(true);
    let animDv = localStorage.getItem('dv');
    let varStartX = (0);
    let varStartY = (0);
    let varEndX = (0);
    let varEndY = (0);
    const trialPeriod = useRef(null);

    // const [infoState, setInfoState] = useState({
    //     msisdn: "",
    //     AccessToken: localStorage.getItem("token"),
    //     // isLoading:false,
    //     clickid: "",
    //     cpid: "",
    //     dataType: null,
    //     p: "",
    //     videos: ""
    // });
    const [videoList, setVideoLists] = useState([]);
    // const calculation = useMemo(() => likeDislikeHandler(), [vKey]);
    const bearerTokenHeader = `Bearer ${localStorage.getItem("token")}`;
    const myCallback = () => {
        // let duration = document.getElementById(vID).duration;
        let path = "/trivia";
        // let vData = {
        //     videoId: currentVideoListIndex.current.vid,
        //     videosWatched: 0,
        //     watchTime: 1,
        // }
        // videoWatched(vData).then(response => {
        navigate(path + "?id=" + currentVideoListIndex.current.vid);
        // }).catch(error => {
        // navigate(path + "?id=" + currentVideoListIndex.current.vid);
        // });

    };

    useEffect(() => {
        document.title = "Home | Ding";
    }, [])
    useEffect(() => {
        // Listen for beforeinstallprompt event
        const handleBeforeInstallPrompt = (event) => {
            // The default browser prompt will be shown
            // when this event handler is called.
            // No need to prevent the default behavior.
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

        return () => {
            // Clean up event listener
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
    }, []);
    useEffect(() => {
        // const demoView = localStorage.getItem('dv');
        // if (demoView!=null)
        // {
        //     // localStorage.setItem("dv",1);
        //     showDemo.current=false;
        // }
        showHideDuringPlay();
        if (getVideosOnceLoad.current) {
            getVideos().then(response => {
                const accessToken = response.headers['access_token'];
                if (accessToken != null) {
                    localStorage.setItem('token', accessToken);
                }
                getVideosOnceLoad.current = false;
                // console.log(response.data.data[0].vid);
                // setLike(response.data.data[0].like);
                // setvKey(0);
                // setvID(response.data.data[0].vid);
                currentVideoListIndex.current = {
                    ...currentVideoListIndex.current,
                    currIndex: 0,
                    vid: response.data.data[0].vid,
                    vkey: 0,
                    like: response.data.data[0].like,
                };
                // console.log(currentVideoListIndex);
                const newVideoValues = {
                    vID: currentVideoListIndex.current.vid,
                    vKey: currentVideoListIndex.current.vkey,
                    like: currentVideoListIndex.current.like,
                };
                handleVideoStateObj(newVideoValues);
                response.data.data.forEach(element => {
                    // videoList.push({"vid":element.vid,"src":element.video,"type":"video/mp4"});
                    setVideoLists(videoList => [...videoList, { "vid": element.vid, "src": element.video, 'like': element.like, "type": "video/mp4", "description": element.description }]);
                })
                // document.getElementById(0).play();
                // localStorage.setItem("dv", 2);
                if (showDemo == false && animDv == 2) {
                    setShowTrial(true);
                    setTimeout(() => {
                        localStorage.setItem("dv", 3);
                        setShowTrial(false)
                        autoPlay(0);
                    }, 3500)

                } else if (showDemo == false && animDv == 3) {
                    autoPlay(0);
                }
                xContextMenu();
                // setVideoLists([...videoList,videoList]);
                // setTimeout(() => {
                //      setIsLoading(false);
                //     //  verifyUser();
                //  }, 2000);
                //  setTimeout(() => { 
                //    verifyUser();
                // }, 30000);
            })
                .catch(error => {
                    // navigate("/login");
                    if (error.response) {
                        navigate("/login");
                        // console.log(error.response.data);
                        // console.log(error.response.status);
                        // console.log(error.response.headers);
                    }
                    // console.log(error.response.status,error.response.data.message);
                    // console.log(error);

                })
        } else {
            if (showDemo == false) {
                autoPlay(0);
                xContextMenu();
            }
        }
        return;
    }, [isloading == true]);
    useEffect(() => {
        if (location.state != null && location.state.uid != null) {
            // console.log(location.state.uid)
            getUidVideo({ "uid": location.state.uid }).then(function (res) {
                hidePopups();
                let newSearch = [...videoList]
                // set only the index you want
                let vKeyId = currentVideoListIndex.current.vkey;
                if (vKeyId === "item_") {
                    vKeyId = 0;
                }
                newSearch[vKeyId].vid = res.data.data.vid;
                newSearch[vKeyId].src = res.data.data.video;
                newSearch[vKeyId].like = res.data.data.like;
                newSearch[vKeyId].description = res.data.data.description;
                // set newPost to the state
                setVideoLists(newSearch);
                setCurrentVideoIndex();
                let v_Elem = document.getElementById(vKeyId);
                let eTargetKey = v_Elem.getAttribute('v-key');
                // console.log(eTargetKey);
                if (!eTargetKey) {
                    let eTargetKeyTemp = v_Elem.getAttribute('v-key1');
                    eTargetKey = eTargetKeyTemp;
                }
                setTimeout(() => {
                    document.getElementById(eTargetKey).currentTime = 0;
                    document.getElementById(eTargetKey).play();
                    // document.getElementById(eTargetKey).play();
                }, 1500);

                showHideDuringPlay();
                // setvKey(eTargetKey);
                if (isPlaying) {
                    setisPlaying(false);
                }
                else {
                    setisPlaying(true);
                }
            }).catch(function (error) {
                console.log(error);
            });
        }
        if (location.state != null && !location.state.uid) {
            // console.log(location.state);
            let nextVideo = location.state.id++;
            if (videoList[nextVideo] == null) {

                return;
            }
            document.getElementById(nextVideo).scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
        navigate(location.pathname);
        return;
    }, [location.state]);

    // const verifyUser = () => {

    //     getVerifyUser()
    //         .then(response => {
    //             // console.log(response.data);
    //             if (response.status === 200 && response.data.status === "Ko") {
    //                 navigate("/login", { state: { phone: response.data.phone } });
    //             }
    //         })
    //         .catch(error => {
    //             console.log(error.response.status, error.response.data.message);
    //         })
    // }


    function xContextMenu() {
        const videoElements = document.querySelectorAll("video");
        for (let i = 0; i < videoElements.length; i++) {
            videoElements[i].setAttribute("contextmenu", "false");
        }
        return true;
    }
    function hideLogoAnimation(hide) {
        if (hide == 0) {
            setTimeout(() => {
                if (showDemo) {
                    autoPlay(0);
                    setShowDemo(false);
                }
            }, 1000);
            setIsLoading(false);
        }
        if (hide == 1) {
            // console.log("show ",hide)
            // setTimeout(() => {
            pauseVideo();
            setIsLoading(false);
            // showDemo.current=false;
            //  verifyUser();
            // }, 1000);

            setShowDemo(true);

        }
    }
    function showHideDuringPlay() {
        const dv = localStorage.getItem("dv");
        // console.log('showhideduringplay', dv)
        if (dv == null) {
            setTimeout(() => {
                hideLogoAnimation(1) //show animation
            }, 3000);
        }
        return true;
    }
    function likeDislikeHandler(like) {
        // let newPost = [...videoList]
        // set only the index you want
        // newPost[vKey].like = like;
        // set newPost to the state
        // setVideoLists(newPost);
        // if (vKey === "item_") {
        //     setVideoLists((prevVideoList) => {
        //         // Create a new array with updated like value
        //         const newVideoList = [...prevVideoList];
        //         newVideoList[vKeyRef.current].like = like;
        //         return newVideoList;
        //     });

        //     const newVideoValues = {
        //         like: currentVideoListIndex.current.like,
        //     };
        //     console.log("like", like)

        //     // Call the function to update the state
        //     handleVideoStateObj(newVideoValues);
        //     // setLike(prevValue => videoList[vKeyRef.current].like);
        // }
        // else {
        setVideoLists((prevVideoList) => {
            // Create a new array with updated like value
            const newVideoList = [...prevVideoList];
            newVideoList[currentVideoListIndex.current.vkey].like = like;
            return newVideoList;
        });
        setCurrentVideoIndex();
        // setLike(prevValue => videoList[vKey].like);
        // }

    }
    function showShareSection() {
        showShare.current = !showShare.current;
    }
    function showSearchSection() {
        // if (vKey === "item_") {
        //     setvKey(0);
        //     document.getElementById(0).pause();
        // } else {
        document.getElementById(currentVideoListIndex.current.vkey).pause();
        setisPlaying(false);
        // }
        showSearch.current = !showSearch.current;
    }
    function copyToClipboardHandler() {
        setCopied(1);
        setTimeout(
            () => setCopied(0),
            600
        );
    }
    function hidePopups() {
        showShare.current = false;
        showSearch.current = false;
    }
    const handleNextItem = () => {
        const newIndex = currentVideoListIndex.current.currIndex + 1;

        if (newIndex <= videoList.length - 1) {
            const nextItem = videoList[newIndex];
            currentVideoListIndex.current = {
                ...currentVideoListIndex.current,
                currIndex: newIndex,
                vid: nextItem.vid,
                vkey: newIndex,
                like: nextItem.like,
            };
            const newVideoValues = {
                vID: currentVideoListIndex.current.vid,
                vKey: currentVideoListIndex.current.vkey,
                like: currentVideoListIndex.current.like,
            };
            handleVideoStateObj(newVideoValues);
            // console.log('Next item:', nextItem, currentVideoListIndex);
        } else {
            // console.log('No more items in the array.');
        }
    };

    const handlePreviousItem = () => {
        const newIndex = currentVideoListIndex.current.currIndex - 1;

        if (newIndex >= 0) {
            const prevItem = videoList[newIndex];
            currentVideoListIndex.current = {
                ...currentVideoListIndex.current,
                currIndex: newIndex,
                vid: prevItem.vid,
                vkey: newIndex,
                like: prevItem.like,
            };
            const newVideoValues = {
                vID: currentVideoListIndex.current.vid,
                vKey: currentVideoListIndex.current.vkey,
                like: currentVideoListIndex.current.like,
            };
            handleVideoStateObj(newVideoValues);
            // console.log('Next item:', prevItem, currentVideoListIndex);
        } else {
            // console.log('No more previous items in the array.');
        }
    };

    const setCurrentVideoIndex = () => {
        const newIndex = currentVideoListIndex.current.currIndex;
        currentVideoListIndex.current = {
            ...currentVideoListIndex.current,
            currIndex: newIndex,
            vid: videoList[newIndex].vid,
            vkey: newIndex,
            like: videoList[newIndex].like,
        };
        const newVideoValues = {
            vID: currentVideoListIndex.current.vid,
            vKey: currentVideoListIndex.current.vkey,
            like: currentVideoListIndex.current.like,
        };
        handleVideoStateObj(newVideoValues);
        // console.log('searched item:', currentVideoListIndex);
    };
    const handleVideoStateObj = (newValues) => {
        // console.log(newValues);
        setCurrentVideStateObj((prevState) => ({
            ...prevState,
            ...newValues,
        }));
    };
    function handleWatchedVideo() {
        let vData = {
            videoId: currentVideoListIndex.current.vid,
            videosWatched: 0,
            watchTime: 1,
        }
        videoWatched(vData)
    }
    const pauseVideo = (key) => {
        let vidElem = document.getElementById(0);
        vidElem.pause();
    }
    const autoPlay = (key) => {
        handleWatchedVideo();
        let autoPlayAllowed = true;
        let v = document.getElementById(0);
        // document.createElement('video');
        // v.src = ""; // we need this

        const promise = v.play();

        if (promise instanceof Promise) {
            promise.catch(function (error) {
                console.log(error.message);
                // Check if it is the right error
                if (error.name === 'NotAllowedError') {
                    autoPlayAllowed = false;
                } else {
                    // Don't throw the error so that we get to the then
                    // or throw it but set the autoPlayAllowed to true in here
                }
            }).then(function () {
                if (autoPlayAllowed) {
                    // Autoplay is allowed - continue with initialization
                    // console.log('autoplay allowed')
                    let vidElem = document.getElementById(0);
                    // console.log(vidElem);
                    vidElem.play();
                    showHideDuringPlay(); //show anim after sometime
                } else {
                    // Autoplay is not allowed - wait for the user to trigger the play button manually
                    // let vidElem;
                    // // console.log('autoplay NOT allowed')
                    // setTimeout(
                    //     () => vidElem = document.getElementById(0),
                    //     vidElem.play(),
                    //     1600
                    // );
                }
            });

        } else {
            // Unknown if allowed
            // Note: you could fallback to simple event listeners in this case
            // console.log('autoplay unknown')
        }
        let video = document.getElementById(0);
        if (!video.paused) {
            // setLike(videoList[0].like);
            // setvKey(0);
            // setvID(videoList[0].vid);
            // console.log("asdfsadfdsaddsdfsaaefewfawewfawe dfdsa fwe", isPlaying)
            if (autoPlayAllowed) {
                setisPlaying(true);
            }
            else {
                setisPlaying(false);
            }
        }
        xContextMenu()

    }
    function handleBuffering() {
        setIsBuffering(true);
    };
    function handleBufferingComplete() {
        setIsBuffering(false);
    }
    // Main function to handle both play and pause operations
    function playPause(e) {
        let eTargetKey = e.target.getAttribute('v-key');
        // console.log(eTargetKey);
        // console.log(eTargetKey);
        if (!eTargetKey) {
            // console.log("!eTargetKey");
            let eTargetKeyTemp = e.target.getAttribute('v-key1');
            // console.log("eTargetKeyTemp", eTargetKeyTemp);
            eTargetKey = eTargetKeyTemp;
            document.getElementById(eTargetKeyTemp).play();
            showHideDuringPlay();
            // setLike(videoList[eTargetKey].like);
            // setLike(prevValue => videoList[eTargetKey].like);
            // setvKey(eTargetKey);
            // setvID(videoList[eTargetKey].vid);
            // setvKey(prevValue => eTargetKey);
            // setvID(prevValue => videoList[eTargetKey].vid);

            if (isPlaying) {
                setisPlaying(false);
            }
            else {
                setisPlaying(true);
            }
            return;
        }

        // let vRefKey = e.target.getAttribute('v-refkey');
        if (currentVideoListIndex.current.vkey !== eTargetKey && isPlaying === true) {
            // console.log("etargetkey"+eTargetKey,"vKey"+vKey);
            document.getElementById(currentVideoListIndex.current.vkey).pause();
            // document.getElementById(vKey).currentTime = 0;
            // setvKey(eTargetKey);
            // setvKey(prevValue => eTargetKey);
            setisPlaying(true);
            // setvID(videoList[eTargetKey].vid);
            // setvID(prevValue => videoList[eTargetKey].vid);
            // setLike(videoList[eTargetKey].like);
            // setLike(prevValue => videoList[eTargetKey].like);
            // setVrefKey(vRefKey);

            // e.target.play();
        } else if (isPlaying) {
            // Pause the song if it is playing
            e.target.pause();
        } else {
            // setvKey(eTargetKey);
            // setvKey(prevValue => eTargetKey);
            // setvID(videoList[eTargetKey].vid);
            // setvID(prevValue => videoList[eTargetKey].vid);
            // setLike(prevValue => videoList[eTargetKey].like);
            // setLike(videoList[eTargetKey].like);
            // setVrefKey(vRefKey);
            // Play the song if it is paused
            e.target.play();
            showHideDuringPlay();
        }
        // Change the state of song
        if (isPlaying) {
            setisPlaying(false);
        }
        else {
            setisPlaying(true);
        }

    }
    function touchstart(e) {
        // showShare.current = false;
        hidePopups();
        const touchObj = e.touches[0];
        varStartX = (touchObj.clientX);
        varStartY = (touchObj.clientY);
        // setStartTouchY(touchObj.clientY);
        // setStartTouchX(touchObj.clientX);
    }
    function touchMove(e) {
        hidePopups();
        // showShare.current = false;
        const touchObj = e.touches[0];
        varEndX = (touchObj.clientX);
        varEndY = (touchObj.clientY);
        // setEndTouchY(touchObj.clientY);
        // setEndTouchX(touchObj.clientX)  
    }
    function touchEnd(e) {
        hidePopups();
        // showShare.current = false;
        let distX = varEndX - varStartX;
        let distY = varEndY - varStartY;

        if (varEndX !== 0 && varEndY !== 0 && Math.abs(distX) >= 120 && distY <= 100) {
            let dir = (distX) < 0 ? 'left' : "right"
            if (dir === "left") {
                if (isPlaying) { document.getElementById(currentVideoListIndex.current.vkey).pause(); }
                if (document.getElementById(currentVideoListIndex.current.vkey)) {
                    setLeftMove(0);
                    // let vRefKey = document.getElementById(currentVideoListIndex.current.vkey).getAttribute('v-refkey');
                    setTimeout(
                        () => navigate("/trivia?id=" + currentVideoListIndex.current.vid),
                        300
                    );
                } else {
                    setLeftMove(0);
                    let vRefKey = document.getElementById("0").getAttribute('v-refkey');
                    setTimeout(
                        () => navigate("/trivia?id=" + vRefKey),
                        300
                    );
                }

            }
            else {
                setLeftMove("101%");
            }

            // console.log(dir,LeftMove);

        } else {
            if (varEndY !== 0) {
                var newDirection = varStartY > varEndY ? "top" : "bottom";
                if (newDirection === "top") {
                    handleNextItem();
                    changePlaybackVideo("top");
                }
                else if (newDirection === "bottom") {
                    handlePreviousItem();
                    changePlaybackVideo("bottom");
                }

            }
        }
        varStartY = 0;
        varEndY = 0;
        // setStartTouchY(0);
        // setEndTouchY(0);
    }
    // const questionTouchEnd = (e) =>{
    //     let distX = EndTouchX-StartTouchX;
    //     let distY = EndTouchY-StartTouchY;
    //     if (Math.abs(distX) >= 120 && distY <=100){
    //         let dir =  (distX)<0? 'left' :"right"
    //         if(dir === "left")
    //         {
    //             setLeftMove(0);
    //         }
    //         else
    //         {
    //             let resumeVideo = document.getElementById(vKey);
    //             if(resumeVideo)
    //             {resumeVideo.play();}
    //             setLeftMove("101%");
    //         }

    //     } else {
    //         console.log('up-down');
    //     }
    // }
    function fetchVideos() {
        if (isPwaInstallShown === false) {
            // setTimeout(() => {
            // console.log(isPwaInstallShown, "pwa shown 652");
            setIsPwaInstallShown(true)
            // }, 3000);
        }
        getVideos(bearerTokenHeader).then(response => {
            const accessToken = response.headers['access_token'];
            if (accessToken != null) {
                localStorage.setItem('token', accessToken);
            }
            response.data.data.forEach(element => {
                // videoList.push({"vid":element.vid,"src":element.video,"type":"video/mp4"});
                setVideoLists(videoList => [...videoList, { "vid": element.vid, "src": element.video, 'like': element.like, "type": "video/mp4", 'description': element.description }]);
                // setInfoState([...videoList,videoList]);
            })
            xContextMenu();
            // console.log(videoList);
        })
            .catch(error => {
                console.log(error.response.status, error.response.data.message);
            })
    }
    function changePlaybackVideo(e) {
        // handleInstallClick();
        let newVid = currentVideoListIndex.current.vkey;
        let oldVid;
        // console.log("oldVid", oldVid);
        // if (vKey === "item_") {
        //     setvKey(0);
        //     newVid = 0;
        //     oldVid = 0;
        // }
        // else {
        //     newVid = currentVideoListIndex.current.vkey;
        //     oldVid = currentVideoListIndex.current.vkey;
        // }
        if (e === 0) {
            return;
        }
        else if (e === "top") {
            newVid = currentVideoListIndex.current.vkey;
            oldVid = newVid - 1;
            // console.log("oldVid_top", oldVid);
        } else if (e === "bottom") {
            newVid = currentVideoListIndex.current.vkey;
            oldVid = newVid + 1;
            // --newVid;
            // console.log("oldVid_bottom", oldVid);
        }
        if (videoList[newVid] == null) {

            return;
        }
        if (videoList.length - newVid === 1) {
            fetchVideos();
        }

        document.getElementById(newVid).scrollIntoView({ behavior: 'smooth', block: 'start' });
        // setvID(newVid);
        // setLike(videoList[newVid].like);
        // setvID(videoList[newVid].vid);
        // setvKey(newVid);
        document.getElementById(oldVid).pause();
        document.getElementById(oldVid).currentTime = 0;
        setTimeout(() => {
            document.getElementById(oldVid).src = ''; //FREE UP MEMORY BY CLEARING THE SRC OF DYNAMICALLY EMBEDED
        }, 300);
        // console.log(videoList[currentVideoListIndex.current.currIndex].src, oldVid);
        document.getElementById(newVid).src = videoList[currentVideoListIndex.current.currIndex].src;
        document.getElementById(newVid).play();
        handleWatchedVideo();
        setisPlaying(true);
    }
    function onKeyDown(e) {
        if (e.which === 123) {
            e.preventDefault();
        }
        if (e.ctrlKey && e.shiftKey && e.which === 73) {
            e.preventDefault();
        }
        if (e.ctrlKey && e.shiftKey && e.which === 75) {
            e.preventDefault();
        }
        if (e.ctrlKey && e.shiftKey && e.which === 67) {
            e.preventDefault();
        }
        if (e.ctrlKey && e.shiftKey && e.which === 74) {
            e.preventDefault();
        }
    }

    function detectDevTool(allow) {
        if (isNaN(+allow)) allow = 100;
        var start = +new Date();
        debugger;
        var end = +new Date();
        if (isNaN(start) || isNaN(end) || end - start > allow) {
            // console.log("DEVTOOLS detected " + allow);
        }
    }

    function useDevToolDetection() {
        useEffect(() => {
            if (window.attachEvent) {
                if (
                    document.readyState === "complete" ||
                    document.readyState === "interactive"
                ) {
                    detectDevTool();
                    window.attachEvent("onresize", detectDevTool);
                    window.attachEvent("onmousemove", detectDevTool);
                    window.attachEvent("onfocus", detectDevTool);
                    window.attachEvent("onblur", detectDevTool);
                } else {
                    setTimeout(useDevToolDetection, 0);
                }
            } else {
                window.addEventListener("load", detectDevTool);
                window.addEventListener("resize", detectDevTool);
                window.addEventListener("mousemove", detectDevTool);
                window.addEventListener("focus", detectDevTool);
                window.addEventListener("blur", detectDevTool);
            }
        }, []);
    }
    useEffect(() => {
        document.body.addEventListener("keydown", onKeyDown);
        return () => {
            document.body.removeEventListener("keydown", onKeyDown);
        };
    }, []);


    // useDevToolDetection();
    // video/mp4; codecs=avc1.640028, mp4a.40.2


    // useEffect(() => {
    //     // Listen for beforeinstallprompt event
    //     const handleBeforeInstallPrompt = (event) => {
    //         // Prevent the default browser prompt
    //         // event.preventDefault();

    //         // Store the event for later use
    //         // setCanShowInstallPrompt(true);
    //     };

    //     window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    //     // Check if PWA is already installed
    //     if (window.matchMedia('(display-mode: standalone)').matches) {
    //         setCanShowInstallPrompt(false);
    //     }

    //     return () => {
    //         // Clean up event listener
    //         window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    //     };
    // }, []);
    // const handleInstallClick = () => {

    //     if (canShowInstallPrompt) {
    //         const beforeInstallPromptEvent = new Event('beforeinstallprompt');
    //         window.dispatchEvent(beforeInstallPromptEvent);
    //     }
    // };
    /* ---------- Stop the timer when the window/tab is inactive ---------- */

    var stopCountingWhenWindowIsInactive = true;

    if (stopCountingWhenWindowIsInactive) {

        if (typeof document.hidden !== "undefined") {
            var hidden = "hidden",
                visibilityChange = "visibilitychange",
                visibilityState = "visibilityState";
        } else if (typeof document.msHidden !== "undefined") {
            var hidden = "msHidden",
                visibilityChange = "msvisibilitychange",
                visibilityState = "msVisibilityState";
        }
        var documentIsHidden = document[hidden];


        document.addEventListener(visibilityChange, function () {
            if (documentIsHidden != document[hidden]) {
                if (window.location.pathname !== "/home") {
                    return;
                }
                if (document[hidden]) {
                    // Window is inactive
                    setisPlaying(false);
                    document.getElementById(currentVideoListIndex.current.vkey).pause();
                } else {
                    // Window is active
                    // setisPlaying(true);
                    setisPlaying(false);
                }
                documentIsHidden = document[hidden];
            }
        });
    }
    return (
        <>
            <Header isPlaying={isPlaying} isBuffering={isBuffering} />
            {isloading ? <LogoAnimation hideLogoAnimation={hideLogoAnimation} /> : null}
            {/* <FirstVisitDemoAnimation hideLogoAnimation={hideLogoAnimation} /> */}
            {showDemo ? <FirstVisitDemoAnimation hideLogoAnimation={hideLogoAnimation} /> : null}
            {getVideosOnceLoad.current && <Loader />}
            {/* {showTrial && <div ref={trialPeriod} className={`${styles.welcomeDingWrapper} text-white`}>
                <h2 style={{ fontSize: "18px", textAlign: "center" }}>
                    60 Seconds Free Trial has been started
                </h2>
            </div>} */}
            <div className={styles.Content} onContextMenu={(e) => e.preventDefault()}>
                <div className={styles.homeContainer} onContextMenu={(e) => e.preventDefault()}>
                    {videoList.map((item, index) =>

                        <div className={styles.videoCard_player} key={`item_${index}`} onTouchStart={touchstart} onTouchMove={touchMove} onTouchEnd={touchEnd}>
                            <div style={{ height: "100%" }}>
                                {!isPlaying && animDv === "3" ?
                                    <div className={styles.playBtnDiv}>
                                        <img src={play} id={styles.playBtnSvg} v-key1={index} onClick={playPause} alt="" />
                                    </div>
                                    :
                                    isPlaying && isBuffering ? <BufferingAnimation />
                                        :
                                        null
                                }

                                <video className={styles.homeVideoContent} src={item.src} type="video/mp4; codecs=avc1.640028, mp4a.40.2" id={index} v-key={index} v-refkey={item.vid} onWaiting={handleBuffering} onPlaying={handleBufferingComplete} onClick={playPause} onEnded={() => myCallback()} preload="metadata" controlsList='nodownload' playsInline={true} webkit-playsinline="true">
                                </video>
                            </div>
                            <div className={styles.videoDescBlock}>
                                <p className={styles.videoDescText}>{item.description}</p>
                            </div>
                        </div>
                    )}
                    <SidebarBottom vid={currentVideStateObj.vID} like={currentVideStateObj.like} likeDislikeHandler={likeDislikeHandler} showShareSection={showShareSection} showSearchSection={showSearchSection} />
                </div>
                <div id={styles.drawer} style={{ left: LeftMove }}>

                </div>
                {/*<SidebarBottom vid={vID} like={vidLike} likeDislikeHandler={likeDislikeHandler} showShareSection={showShareSection} />*/}
                {/* {isPwaInstallShown
                    && (<PwaNotification isPwaInstallShown={isPwaInstallShown} />)} */}

                <PwaNotification isPwaInstallShown={isPwaInstallShown} />
                {copied ? <LinkCopied /> : null}
                <div className={(showShare.current) ? `${styles.slideInUp}` : `${styles.slideOutDown}`} onContextMenu={(e) => e.preventDefault()}>
                    <Share vid={currentVideStateObj.vID} copyToClipboardHandler={copyToClipboardHandler} />
                </div>
                <div className={(showSearch.current) ? `${styles.slideInUpSearch}` : `${styles.slideOutDown}`} onContextMenu={(e) => e.preventDefault()}>
                    <SearchPopup />
                </div>
            </div>
        </>
    );
}