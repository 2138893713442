import React, { useEffect, useState } from 'react';
import styles from '../../styles/home/sidebarBottom.module.css';
import '../../styles/home/svg_styles.css';
import { Link } from 'react-router-dom';
import { likeDislike } from "../../ApiCalls/Home/api_v1_home";

function SidebarBottomSavedVideos({ vid, like, likeDislikeHandler, showShareSection, showSearchSection }) {
    // const [heartRed, setHeartRed] = useState(like);
    // useEffect(() => {
    //     setHeartRed(like);
    // }, [like])

    // let varLikeVideo = likeVideo
    // console.log(vid);
    function handleLike() {
        likeDislikeHandler(like === 1 ? 0 : 1);
        // setHeartRed(like);
        let likeData = {
            "vid": vid,
        }
        likeDislike(likeData).then(response => {
            const accessToken = response.headers['access_token'];
            if (accessToken != null) {
                localStorage.setItem('token', accessToken);
            }
            // likeDislikeHandler(response.data.data.videoLikes);
            // setHeartRed(response.data.data.videoLikes);
            // varLikeVideo=  response.data.data.videoLikes
        })
            .catch(error => {
                // .response.status,error.response.data.message
                // console.log(error.response.status,error.response.data.message);
            })
    }

    return (
        <>
            <div className={styles.sidebarBottomContainer}>
                <div className={styles.sidebarBtnHeart}>
                    <Link role="button" onClick={handleLike} >
                        {like ?
                            <svg className={styles.svgSize} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64.62 53.47"><defs></defs><g id="Artboard_1" data-name="Artboard 1" className="cls-111"><g id="share_icon_Image" data-name="share icon Image"><path className="cls-333" d="M32.31,51.69,23.48,46a104.45,104.45,0,0,1-8.78-6.45C8.55,34.47,1.5,27.43,1.5,17.77a16.27,16.27,0,0,1,30.81-7.31,16.27,16.27,0,0,1,30.81,7.31c0,9.66-7,16.7-13.2,21.76A103.32,103.32,0,0,1,41.15,46Z" /><path className="cls-444" d="M32.31,51.69,23.48,46a104.45,104.45,0,0,1-8.78-6.45C8.55,34.47,1.5,27.43,1.5,17.77a16.27,16.27,0,0,1,30.81-7.31,16.27,16.27,0,0,1,30.81,7.31c0,9.66-7,16.7-13.2,21.76A103.32,103.32,0,0,1,41.15,46Z" /></g></g></svg>
                            :
                            <svg className={styles.svgSize} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64.62 53.47"><defs></defs><g id="Artboard_1" data-name="Artboard 1" className="cls-11"><g id="share_icon_Image" data-name="share icon Image"><path className="cls-33" d="M32.31,51.69,23.48,46a104.45,104.45,0,0,1-8.78-6.45C8.55,34.47,1.5,27.43,1.5,17.77a16.27,16.27,0,0,1,30.81-7.31,16.27,16.27,0,0,1,30.81,7.31c0,9.66-7,16.7-13.2,21.76A103.32,103.32,0,0,1,41.15,46Z" /></g></g></svg>
                        }
                    </Link>
                </div>
                <div className={styles.sidebarBtnShare}>
                    <Link role="button" onClick={() => showShareSection()} className={styles.sidebarLink}>
                        <svg className={styles.svgSize} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64.91 57.96"><defs></defs><g id="Artboard_1" data-name="Artboard 1" className="cls-1"><g id="share_icon_Image" data-name="share icon Image"><path className="cls-3" d="M34.43,34,34.85,46A.73.73,0,0,0,36,46.6l27.1-21.91a.73.73,0,0,0,0-1.13L36,1.66a.73.73,0,0,0-1.19.56l-.54,10c-5.43,1-10,2.42-14.45,5.21A40.38,40.38,0,0,0,8.14,28.55,37.55,37.55,0,0,0,2.2,42.44a34.81,34.81,0,0,0,0,14h.68A31.17,31.17,0,0,1,8,44.86,28.5,28.5,0,0,1,16.61,37a25.37,25.37,0,0,1,10-3.35A45.4,45.4,0,0,1,34.43,34Z" /></g></g></svg>
                    </Link>
                </div>
            </div>
        </>
    );
}

// export default React.memo(SidebarBottom);
export default SidebarBottomSavedVideos;