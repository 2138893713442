import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Routes, Route, } from "react-router-dom";
// import { useEffect } from 'react';
import GoogleAnalytics from './components/analytics/GoogleAnalyticsConditional';
import Login from './components/login/Login';
import Home from "./components/home/Home";
// import HomeWithoutSplash from "./components/home/Home";
// import SidebarBottom from "./components/home/SidebarBottom";
// import Header from './components/header/Header';
import Footer from "./components/footer/Footer";
import RedeemHeader from "./components/redeem/RedeemHeader";
import RedeemBody from "./components/redeem/RedeemBody";
import SavedVideos from "./components/savedVideos/SavedVideos";
import Categories from "./components/savedVideos/Categories";
import Profile from "./components/profile/Profile";
import QuestionTime from "./components/question/QuestionTime";
import Rewards from "./components/reward/Rewards";
import ThankYou from './components/redeem/ThankYou';
import ProtectedRoutes from './components/Auth/Protected';
import WatchSaved from './components/savedVideos/watchSaved';
import WatchSharedVideo from './components/SharedVideo/WatchShareVideo';
import EditProfile from './components/profile/EditProfile';
import Unsub from './components/Unsubscribe/Unsub';
import "./TimeRecorder/TimeRecorder";
import RewardsNew from './components/reward/RewardsNew';
import SpinWheel from './components/reward/SpinWheel';
import Leaderboard from './components/leaderboard/Leaderboard';
import AppBar from './components/header/Appbar';
import ProfileNew from './components/profile/ProfileNew';
import ProfileSettings from './components/profile/ProfileSettings';
import AvatarEditorStylized from './components/profile/AvatarEditor';
import TermsOfService from './components/profile/TermsOfService';
import FAQs from './components/profile/FAQs';
import AvatarChanges from './components/profile/AvatarChanges';
import { CoinProvider } from './components/header/CoinContext';
// import BottomAppBar from './components/footer/BottomAppBar';

function App() {
  return (
    <div>
      <GoogleAnalytics
        trackingId="G-5N4XGKLXTT"  // DING JAZZ ID for the login page
      />
      <Routes>
        <Route path="/login" element={<><Login /></>}> </Route>
        {/* <Route element={<ProtectedRoutes />}> */}
          <Route path='/terms-service' element={<CoinProvider> <AppBar title="Terms Of Service" /> <TermsOfService />  </CoinProvider>} ></Route>
          <Route path='/faqs' element={<CoinProvider> <AppBar title="FAQs" /> <FAQs />  </CoinProvider>} ></Route>
          <Route path='/avatar-changes' element={<CoinProvider> <AppBar title="Profile" /> <AvatarChanges />  </CoinProvider>} ></Route>

          <Route path="/" element={<> <Home showSplash={true} /><Footer /> </>}> </Route>
          <Route path="/home" element={<> <Home showSplash={true} /><Footer /> </>}> </Route>
          <Route path="/home/landing" element={<> <Home showSplash={false} /><Footer /> </>}> </Route>
          <Route path="/redeem" element={<CoinProvider> <AppBar title="Redeem" /><RedeemBody /> </CoinProvider>}> </Route>
          <Route path="/profile" element={<CoinProvider> <AppBar title="Profile" /> <ProfileNew /><Footer /> </CoinProvider>}> </Route>
          <Route path="/edit-profile" element={<CoinProvider> <AppBar title="Edit Profile" />  <EditProfile /><Footer /> </CoinProvider>}> </Route>
          <Route path="/categories" element={<CoinProvider> <AppBar title="Categories" /> <Categories /> <br /> <br /> <br /> <Footer /></CoinProvider>}> </Route>
          <Route path="/shared/video" element={<> <WatchSharedVideo /><Footer /></>}> </Route>
          <Route path="/saved/watch" element={<> <WatchSaved /><Footer /></>}> </Route>
          <Route path="/trivia" element={<CoinProvider> <AppBar title="Trivia" /> <QuestionTime /><Footer /> </CoinProvider>}> </Route>
          <Route path="/rewards" element={<> <Rewards /><Footer /></>}> </Route>
          <Route path="/thankyou" element={<><ThankYou /></>}> </Route>
          <Route path="/unsubscribe" element={<><Unsub /></>}> </Route>
          <Route path="/rewards-new" element={<CoinProvider><AppBar title="Rewards" /> <RewardsNew /><Footer /></CoinProvider>}> </Route>
          <Route path='/spin-wheel' element={<CoinProvider> <AppBar title="Spin Wheel" /> <SpinWheel /></CoinProvider>}></Route>
          <Route path='/leaderboard' element={<CoinProvider> <AppBar title="Leaderboard " /> <Leaderboard /> <Footer /> </CoinProvider>}></Route>
          <Route path='/profile-settings' element={<CoinProvider><AppBar title="Settings" /> <ProfileSettings /> </CoinProvider>} ></Route>
          <Route path='/analytics' element={<CoinProvider> <AppBar title="Analytics" /> <Profile /> </CoinProvider>} ></Route>
          <Route path='/avatar-editor' element={<CoinProvider> <AppBar title="Edit Avatar" /> <AvatarEditorStylized /> </CoinProvider>} ></Route>
          <Route path="/saved" element={<CoinProvider> <AppBar title="Saved" /> <SavedVideos />  <Footer /></CoinProvider>}> </Route>
        {/* </Route> */}
      </Routes>
    </div>
  );
}

export default App;