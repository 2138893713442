import React from 'react';
import styles from '../../styles/animations_styles/loaderBrown.module.css';

const LoaderBrown = () => {



    return (
        <>
            <section className={styles.loaderSection}>
                <div className={styles.lds_ring}><div></div><div></div><div></div><div></div></div>
                <h1 className={styles.loaderSecitionh1}>
                    Loading...
                </h1>
            </section>

        </>
    );
}

export default React.memo(LoaderBrown);