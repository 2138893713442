import React from 'react';
import styles from '../../styles/animations_styles/loaderWhite.module.css';
const BufferingAnimation = () => {
    return (
        <>
            {/* <div className="bufferingAnimation">Loading...</div> */}
            <section className={styles.loaderSection}>
                <div className={styles.lds_ring}><div></div><div></div><div></div><div></div></div>
                <h1 className={styles.loaderSecitionh1}>
                    Loading...
                </h1>
            </section>
        </>
    )
}
export default React.memo(BufferingAnimation);
