import React from 'react';

import monthlyChallenge from '../../../images/v1/rewards/monthlyChallenge.svg'

const MonthlyChallenge = () => {
  return (
    <div className="monthly-challenge">
      <div className="blur left-blur"></div>
      <div className="blur bottom-blur-monthly"></div>
      <div className="blur right-blur-monthly"></div>
      <div className="content">
        <div className="text">
          <h2 className='stroke-color-black'>Monthly Streak Reward</h2>
          <p className='stroke-color-black'>Complete a streak up to 4 weeks and unlock the treasure box</p>
        </div>
        <div className="image">
          <img src={monthlyChallenge} alt="Reward box" />
        </div>
      </div>
      <style jsx>{`
        .monthly-challenge {
          background-color: #FFB985;
          border-radius: 20px;
          padding: 20px;
          position: relative;
          overflow: hidden;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        }

        .blur {
          position: absolute;
          filter: blur(30px);
          opacity: 0.3;
        }

        .left-blur {
          background-color: #5FF5EF;
          width: 100px;
          height: 100%;
          top: 0;
          left: -50px;
        }

        .bottom-blur-monthly {
          background-color: #F48000;
          width: 100%;
          height: 60px;
          bottom: -30px;
          left: 0;
        }

        .right-blur-monthly {
          background-color: #EA5E22;
          width: 60px;
          height: 100%;
          top: 0;
          right: -30px;
        }

        .image {
          margin-left: 20px;
        }

        .image img {
          width: 80px;
          height: 80px;
          object-fit: contain;
        }
      `}</style>
    </div>
  );
};

export default MonthlyChallenge;