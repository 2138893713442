import axios from 'axios';

const axiosClient = axios.create({
  // `${process.env.REACT_APP_BASE_URL}`
  // "http://localhost/ding_v1_staging_backend/public/api/v1"
  // "http://127.0.0.1:8000/api/v1"
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
});


axiosClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    let res = error.response;
    if (res.status == 401) {
      window.location.href = `${process.env.REACT_APP_ERROR_PAGE}`;
    }
    console.error("Looks like there was a problem. Status Code: " + res.status);
    return Promise.reject(error);
  }
);


export default axiosClient;