import React, { useState, useEffect, useRef } from 'react';
import TabSelector from './TabSelector';
import AvatarBuilder from '../avatar/components/AvatarProperties';
import { getLeaderboard } from '../../ApiCalls/Rewards/api_v1_rewards';
import '../../styles/leaderboard/leaderboard.css';
import CryptoJS from 'crypto-js';
import ding_token from '../../images/v1/home/ding_token.png';
import timerIcon from '../../images/v1/leaderboard/timer.png'
import pouchIcon from '../../images/v1/leaderboard/pouch.svg'

const STORAGE_KEY = 'encryptedLeaderboardData';
const ENCRYPTION_KEY = process.env.REACT_APP_LEADERBOARD;


function Leaderboard() {
  const [activeTab, setActiveTab] = useState(0);
  const [leaderboardData, setLeaderboardData] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [error, setError] = useState(null);
  const startY = useRef(0);
  const currentY = useRef(0);
  const refreshThreshold = 50;

  const tabs = [
    { name: 'Coins Earned', key: 'coins_earned', title: 'Coins Earned', description: 'Stand out on your exceptional performance!', activeColor: '#FF9900', bgColor: '#FDEDAA', icon: ding_token },
    { name: 'Watch Time', key: 'watch_time', title: 'Watch Time', description: 'Stand out on your exceptional performance!', activeColor: '#2F72FE', bgColor: '#CBD8F5', icon: timerIcon },
    { name: 'Rewards Redeemed', key: 'rewards_redeemed', title: 'Rewards Redeemed', description: 'Stand out on your exceptional performance!', activeColor: '#F75555', bgColor: '#F5D5D5', icon: pouchIcon },
    { name: 'Trivia Champions', key: 'trivia_Champ', title: 'Trivia Champions', description: 'Stand out on your exceptional performance!', activeColor: '#4AAF57', bgColor: '#D3F0DE', icon: pouchIcon }
  ];

  useEffect(() => {
    if (!ENCRYPTION_KEY) {
      fetchLeaderboardData();
    } else {
      loadStoredData();
    }
  }, []);

  const encryptData = (data) => {
    if (!ENCRYPTION_KEY) return JSON.stringify(data); // Fallback to storing unencrypted
    return CryptoJS.AES.encrypt(JSON.stringify(data), ENCRYPTION_KEY).toString();
  };

  const decryptData = (encryptedData) => {
    if (!ENCRYPTION_KEY) return JSON.parse(encryptedData); // Fallback to parsing unencrypted
    const bytes = CryptoJS.AES.decrypt(encryptedData, ENCRYPTION_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  };

  const storeData = (data) => {
    const processedData = encryptData(data);
    localStorage.setItem(STORAGE_KEY, processedData);
  };

  const loadStoredData = () => {
    const storedData = localStorage.getItem(STORAGE_KEY);
    if (storedData) {
      try {
        const processedData = decryptData(storedData);
        setLeaderboardData(processedData);
      } catch (error) {
        console.error('Error processing stored data:', error);
        setError('Error loading stored data. Fetching new data.');
        fetchLeaderboardData();
      }
    } else {
      fetchLeaderboardData();
    }
  };

  const handleTouchStart = (e) => {
    startY.current = e.touches[0].clientY;
  };

  const handleTouchMove = (e) => {
    currentY.current = e.touches[0].clientY;
    if (currentY.current - startY.current > refreshThreshold && !isRefreshing) {
      setIsRefreshing(true);
    }
  };

  const handleTouchEnd = () => {
    if (isRefreshing) {
      fetchLeaderboardData();
    }
    startY.current = 0;
    currentY.current = 0;
  };

  const fetchLeaderboardData = async () => {
    setError(null);
    setIsRefreshing(true);
    try {
      const response = await getLeaderboard();
      if (response.data.message === "ok") {
        setLeaderboardData(response.data.data);
        storeData(response.data.data);
      } else {
        throw new Error("Error in API response: " + response.data.message);
      }
    } catch (error) {
      console.error("Error fetching leaderboard data:", error);
      setError("Failed to fetch leaderboard data. Please try again later.");
    } finally {
      setIsRefreshing(false);
    }
  };

  const getDisplayName = (entry) => {
    return entry.name || entry.phone;
  };

  const getScoreValue = (entry, key) => {
    switch (key) {
      case 'coins_earned':
        return entry.coins;
      case 'watch_time':
        return entry.watchTime;
      case 'rewards_redeemed':
        return entry.rewardsRedeemed;
      case 'trivia_Champ':
        return entry.triviaChamp;
      default:
        return '';
    }
  };

  const formatScore = (score, key) => {
    if (key === 'watch_time') return score;
    return score.toLocaleString();
  };

  const renderScoreWithIcon = (score, key) => {
    const icon = tabs.find(tab => tab.key === key).icon;
    return (
      <div className="leaderboard-score-container">
        <img src={icon} alt={key} className="leaderboard-score-icon" />
        <span className="leaderboard-score">{formatScore(score, key)}</span>
      </div>
    );
  };

  const renderLeaderboard = () => {
    if (error) return <div className="error-message">{error}</div>;
    if (!leaderboardData) return <div>Loading...</div>;

    const currentData = leaderboardData[tabs[activeTab].key];
    if (!currentData || currentData.length === 0) return <div>No data available</div>;

    const topThreeOrder = [1, 0, 2];

    return (
      <>
        <div className="leaderboard-top-three">
          {topThreeOrder.map((index) => {
            const entry = currentData[index];
            if (!entry) return null;
            return (
              <div key={index} className={`leaderboard-avatar-container top-${index + 1}`}>
                <div className="leaderboard-avatar-wrapper">
                  <div className="leaderboard-rank-badge">#{index + 1}</div>

                  <div className="leaderboard-avatar-inner">
                    <AvatarBuilder />
                  </div>
                </div>
                <div className="leaderboard-name">{getDisplayName(entry)}</div>
                {renderScoreWithIcon(getScoreValue(entry, tabs[activeTab].key), tabs[activeTab].key)}
              </div>
            );
          })}
        </div>

        <ul className="leaderboard-list">
          {currentData.slice(3).map((entry, index) => (
            <li key={index} className="leaderboard-item">
              <span className="leaderboard-rank">{index + 4}</span>
              <span className="leaderboard-name">{getDisplayName(entry)}</span>
              {renderScoreWithIcon(getScoreValue(entry, tabs[activeTab].key), tabs[activeTab].key)}
            </li>
          ))}
        </ul>
      </>
    );
  };

  return (
    <div
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      {isRefreshing && (
        <div className="refreshing-indicator">
          <div className="spinner"></div>
        </div>
      )}
      <TabSelector tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
      <div className="leaderboard-container">
        <h1 className="leaderboard-title">• LEADERBOARD •</h1>
        <h2 className="leaderboard-subtitle">{tabs[activeTab].title}</h2>
        <p className="leaderboard-description">{tabs[activeTab].description}</p>
        {renderLeaderboard()}
      </div>
      <br /> <br /> <br />
    </div>
  );
}

export default Leaderboard;