import React, { useState } from 'react';

const FAQItem = ({ question, answer, isOpen, toggleOpen }) => (
  <div className="faq-item">
    <div className="faq-question" onClick={toggleOpen}>
      {question}
      <span className={`arrow ${isOpen ? 'open' : ''}`}>▼</span>
    </div>
    {isOpen && <div className="faq-answer">{answer}</div>}
  </div>
);

const FAQs = () => {
  const faqs = [
    { question: "Is there a reward for downloading the Ding app?", answer: "Yes, new and existing users are encouraged to download the app from the store and will receive 1000 bonus coins upon download and login" },
    { question: "Is there a trial period for Ding?", answer: "Yes, new users receive a one-day free trial upon subscribing, allowing them to explore the app's features before any charges apply" },
    { question: "How do I earn coins in Ding?", answer: "You can earn coins by watching videos, logging in daily, completing weekly and monthly streaks, participating in weekly trivia, sharing videos on social media, and more. These coins can be redeemed for in-game currencies like PUBG, Fortnite, Roblox, and Call of Duty and more" },
    { question: "How does the weekly and monthly streak system work?", answer: "Log in daily to earn coins and complete streaks. A weekly streak rewards you with coins, and completing four weekly streaks rewards you even more as part of the monthly streak" },
    { question: "Can I share videos from Ding?", answer: "Yes, you can share videos with your friends on social platforms. After verification, you will receive coins for sharing" },
    { question: "I did not receive my OTP. What should I do?", answer: "If you did not receive your OTP, ensure that your mobile number is correct and request the OTP again after two minutes. Make sure you are in the coverage area. If the issue persists, contact customer support for assistance" },
    { question: "What are themes and how do I change them?", answer: "Ding offers various themes to personalize your app experience. Changing themes requires spending Ding coins" },
    { question: "What happens if I miss a day in my weekly streak?", answer: "Missing a day will reset your weekly streak, and you'll need to start over to earn the weekly streak bonus" },
    { question: "How does the weekly trivia work?", answer: "Weekly trivia challenges consist of 10 questions, each displayed for 10 seconds. The winner of each week's trivia receives coin rewards" },
    { question: "Where can I find my trivia results?", answer: "You can view your trivia results and rankings in the \"Trivia Champions\" leaderboard section" },
    { question: "What leaderboards are available in Ding?", answer: "Ding features leaderboards for Coins Earned, Watch Time, Rewards Redeemed, and Trivia Champions. These categories recognize top users based on their activities and performance" },
    { question: "What is the \"Play the Game\" feature?", answer: "\"Play the Game\" allows users to spin a virtual wheel to earn coins. You can play for free once and win coins based on where the wheel stops. To play again immediately, you can spend coins, or wait for the free play timer to reset" },
  ];

  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="faq-page">
      <h1>Frequently Asked Questions</h1>
      <div className="faq-list">
        {faqs.map((faq, index) => (
          <FAQItem
            key={index}
            question={faq.question}
            answer={faq.answer}
            isOpen={openIndex === index}
            toggleOpen={() => toggleFAQ(index)}
          />
        ))}
      </div>

      <style jsx>{`
        .faq-page {
          background-color: #1a1a1a;
          color: #ffffff;
          min-height: 100vh;
          padding: 20px;
          font-family: Arial, sans-serif;
        }

        h1 {
          text-align: center;
          margin-bottom: 20px;
        }

        .faq-list {
          max-width: 800px;
          margin: 0 auto;
        }

        .faq-item {
          margin-bottom: 10px;
        }

        .faq-question {
          padding: 15px;
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-weight: bold;
          background: #252525;
          border-radius: 8px;
        }

        .arrow {
          transition: transform 0.3s ease;
        }

        .arrow.open {
          transform: rotate(180deg);
        }

        .faq-answer {
          padding: 15px;
          background-color: #252525;
          line-height: 1.6;
          border-radius: 8px;
        }
      `}</style>
    </div>
  );
};

export default FAQs;