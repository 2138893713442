import React, { useLayoutEffect, useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import SfxMainAudio from "../AutoPlayAudio/SfxMainAudio";
import styles from '../../styles/savedVideos/SavedVideos.module.css';
import { getSavedVideos, getSavedVideosByCategory } from "../../ApiCalls/SavedVideos/api_v1_saved_videos";
import Categories from "./Categories";
import leftArrow from "../../images/v1/icons/left_arrow_white.png";
import BufferingAnimation from '../Animations/BufferingAnimation';

import savedIcon from '../../images/v1/home/play_saved.svg'

function SavedVideos() {
    let navigate = useNavigate();
    const [videoList, setVideoLists] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [showCategories, setShowCategories] = useState(false);
    const [categoryName, setCategoryName] = useState("");
    const [showBufferAnim, setsShowBufferAnim] = useState(true);
    useLayoutEffect(() => {
        document.body.style.backgroundColor = "#191919";
        document.querySelector("meta[name='theme-color']").setAttribute("content", "#191919");
        document.title = "Saved | Ding";
    }, []);
    useEffect(() => {
        if (isLoaded === false) {
            getSavedVideos().then(response => {
                if (response.status === 200 && response.data.message === "Ko") {
                    return;
                }
                if (response.status === 200 && response.data.message === "ok") {
                    setVideoLists([]);
                    response.data.data.forEach(element => {
                        setVideoLists(videoList => [...videoList, { "vid": btoa(element.vid), "src": element.poster, "type": "video/mp4" }]);

                    })
                    setIsLoaded(true);
                    return;

                }

            }).catch(error => {
                console.log(error);

            });
        }
    }, [isLoaded === false]);

    function getCategory(data) {
        setVideoLists([]);
        handleToggle(2);
        setCategoryName(data.category);
        setsShowBufferAnim(true);
        getSavedVideosByCategory(data).then(response => {
            if (response.status === 200 && response.data.message === "Ko") {

                // console.log("no videos found");
                return;
            }
            if (response.status === 200 && response.data.message === "ok") {
                response.data.data.forEach(element => {
                    setVideoLists(videoList => [...videoList, { "vid": btoa(element.vid), "src": element.poster + "#t=0.1", "type": "video/mp4" }]);

                })

                setsShowBufferAnim(false);
                return;

            }

        }).catch(error => {
            // console.log(error.response.status,error.response.data.message);
            console.log(error);

        });
    }

    function handleToggle(e) {
        if (e === 1) {
            setShowCategories(prevState => false); 
            setIsLoaded(false);
        }
        if (e === 2) {
            setShowCategories(prevState => true);
        }

    }
    function navigateHome() {
        return navigate("/home/landing");
    }

    return (
        <>
            <SfxMainAudio />
            <main>
                <div className='align-flex-start'>
                    <h1 className='ml-3'>Categories</h1>
                    <Categories getCategory={getCategory} />
                </div>
                {showCategories ?
                    <section className={styles.vidoesContainer2}>
                        {showBufferAnim ? <BufferingAnimation /> :
                            <>
                                {videoList.map((item, index) => (
                                    <Link
                                        to={`/saved/watch?v=${item.vid}`}
                                        key={`linkKey_${index}`}
                                        id={index}
                                        link-key={index}
                                        v-refkey={item.vid}
                                        className={styles.videoLink}
                                    >
                                        <div className={styles.thumbnailContainer}>
                                            <img src={item.src} key={`posterKey_${index}`} alt="loading..." className={styles.videoImg} />
                                            <img src={savedIcon} alt="Saved Icon" className={styles.savedIcon} />
                                        </div>
                                    </Link>
                                ))}
                                {!videoList.length ? <p className="mt-2 mx-auto">NO VIDEOS FOUND</p> : null}
                            </>
                        }
                    </section>
                    :
                    <div className='align-flex-start ml-3'>
                        <h1>Saved Videos</h1>
                        {isLoaded ?
                            <section className={styles.vidoesContainer}>
                                {videoList.map((item, index) => (
                                    <Link
                                        to={`/saved/watch?v=${item.vid}`}
                                        key={`linkKey_${index}`}
                                        id={index}
                                        link-key={index}
                                        v-refkey={item.vid}
                                        className={styles.videoLink}
                                    >
                                        <div className={styles.thumbnailContainer}>
                                            <img src={item.src} key={`posterKey_${index}`} alt="loading..." className={styles.videoImg} />
                                            <img src={savedIcon} alt="Saved Icon" className={styles.savedIcon} />
                                        </div>
                                    </Link>
                                ))}
                                {!videoList.length ? <p className="mt-2 mx-auto">NO VIDEOS SAVED</p> : null}
                            </section>
                            : <BufferingAnimation />
                        }
                    </div>
                }

            </main>
        </>
    );
}


export default React.memo(SavedVideos)