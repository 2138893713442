import React from 'react';
import Skin from './Skin';
import Eyes from './face/Eyes';
import Mouth from './face/Mouth';
import Clothes from './clothes/Clothes';
import Nose from './face/Nose';
import HairStyle from './top/HairStyle';
import FacialHair from './top/FacialHair';
import Eyebrow from './face/Eyebrow';
import Accessories from './top/Accessories';

export const AvatarProperties = {
    emptySVGIcon: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-20 -20 80 80" width="80px" height="80px">
      <path fill="#dff0fe" stroke="#4788c7" stroke-miterlimit="10" d="M20,1C9.507,1,1,9.507,1,20s8.507,19,19,19s19-8.507,19-19S30.493,1,20,1z M6,20c0-7.732,6.268-14,14-14c2.963,0,5.706,0.926,7.968,2.496L8.496,27.968C6.926,25.706,6,22.963,6,20z M20,34 c-2.963,0-5.706-0.926-7.968-2.496l19.472-19.472C33.074,14.294,34,17.037,34,20C34,27.732,27.732,34,20,34z"/>
    </svg>
  `,

    defaultProperties: {
        style: "Transparent",
        topType: "Flat",
        accessoriesType: "Nothing",
        hairColor: "Black",
        facialHairType: "Nothing",
        facialHairColor: "BlondeGolden",
        clotheType: "Crew Neck Tee",
        clotheColor: "Blue01",
        eyeType: "Default",
        eyebrowType: "Default",
        mouthType: "Default",
        skinColor: "White"
    },

    topTypeOptions: [
        "Bald", "Eyepatch", "Hijab", "Turban", "Hat", "I'm not bald", "Flat",
        "from School", "Short n waved", "Short n curly", "The caesar", "Another caeser",
        "Short w/ dreads", "Short w/ lots of dreads", "Bob cut", "Bun", "Fro", "Its not short",
        "Long (Female)", "Curvy", "Shaggy", "Short w/ frizzle", "LongHairStraight",
        "LongHairStraight2", "LongHairStraightStrand", "Curly (Female)", "Long w/ dreads",
        "Frida", "Fro w/ band", "Long w/ shaved sides", "Mia Wallace", "WinterHat1",
        "WinterHat2", "Santa Hat", "Santa Hat w/ ears"
    ],

    accessoriesTypeOptions: [
        "Nothing", "PrescriptionGlasses", "Another pair of prescriptions", "Round",
        "Sunglasses", "Wayfarers", "Kurt"
    ],

    hairColorOptions: [
        "Auburn", "Black", "Brown", "Blonde", "BlondeGolden", "BrownDark",
        "PastelPink", "Platinum", "Red", "SilverGray"
    ],

    facialHairTypeOptions: [
        "Nothing", "Full Beard", "Beard, Light", "Moustache, Fancy", "That 80's moustache"
    ],

    facialHairColorOptions: [
        "Auburn", "Black", "Blonde", "BlondeGolden", "Brown", "BrownDark", "Platinum", "Red"
    ],

    clotheTypeOptions: [
        "Crew Neck Tee", "GraphicShirt", "Scoop-neck Tee", "ShirtVNeck", "Hoodie",
        "CollarSweater", "Overall", "Blazer + T-Shirt", "BlazerSweater"
    ],

    clotheColorOptions: [
        "Blue01", "Blue02", "PastelYellow", "PastelGreen", "Gray02", "Black",
        "Blue03", "Gray01", "Heather", "PastelBlue", "PastelOrange", "PastelRed",
        "Pink", "Red", "White"
    ],

    eyeTypeOptions: [
        "Default",
        "Happy",
        "Squint",
        "Close",
        "Cry",
        "Dizzy",
        "EyeRoll",
        "Hearts",
        "Side",
        "Surprised",
        "Wink",
        "WinkWacky"
    ],

    eyebrowTypeOptions: [
        "Default",
        "FlatNatural",
        "Angry",
        "AngryNatural",
        "DefaultNatural",
        "RaisedExcited",
        "RaisedExcitedNatural",
        "SadConcerned",
        "SadConcernedNatural",
        "UnibrowNatural",
        "UpDown",
        "UpDownNatural"
    ],

    mouthTypeOptions: [
        "Concerned",
        "Default",
        "Sad",
        "Disbelief",
        "Eating",
        "Grimace",
        "ScreamOpen",
        "Serious",
        "Smile",
        "Tongue",
        "Twinkle",
        "Vomit"
    ],

    skinColorOptions: [
        "White",
        "Peach",
        "Black",
        "Brown",
        "DarkBrown",
        "Yellow",
        "Tanned"
    ]
};

const Avatar = ({ properties }) => {
    const {
        topType, accessoriesType, hairColor, facialHairType, facialHairColor,
        clotheType, clotheColor, eyeType, eyebrowType, mouthType, skinColor
    } = properties;

    const noseSvg = Nose({ noseType: "Default" })
    const hairSvg = HairStyle({ hairType: topType, hColor: hairColor })
    const facialHairSvg = FacialHair({ facialHairType: facialHairType, fhColor: facialHairColor })
    const eyeBrowSvg = Eyebrow({ eyebrowType: eyebrowType })
    const accessoriesTypeSvg = Accessories({ accessoryType: accessoriesType })
    const skinSVG = Skin({ skinType: skinColor })
    const eyesSVG = Eyes({ eyeType: eyeType })
    const mouthSVG = Mouth({ mouthType: mouthType })
    const clothesSVG = Clothes({ clotheType: clotheType, clColor: clotheColor })


    const avatarSVG = `
    <svg width="264px" height="280px" viewBox="0 0 264 280" version="1.1"
         xmlns="http://www.w3.org/2000/svg"
         xmlns:xlink="http://www.w3.org/1999/xlink">
          <defs>
                        <circle id="path-1" cx="120" cy="120" r="120"></circle>
                        <path d="M12,160 C12,226.27417 65.72583,280 132,280 C198.27417,280 252,226.27417 252,160 L264,160 L264,-1.42108547e-14 L-3.19744231e-14,-1.42108547e-14 L-3.19744231e-14,160 L12,160 Z" id="path-3"></path>
                        <path d="M124,144.610951 L124,163 L128,163 L128,163 C167.764502,163 200,195.235498 200,235 L200,244 L0,244 L0,235 C-4.86974701e-15,195.235498 32.235498,163 72,163 L72,163 L76,163 L76,144.610951 C58.7626345,136.422372 46.3722246,119.687011 44.3051388,99.8812385 C38.4803105,99.0577866 34,94.0521096 34,88 L34,74 C34,68.0540074 38.3245733,63.1180731 44,62.1659169 L44,56 L44,56 C44,25.072054 69.072054,5.68137151e-15 100,0 L100,0 L100,0 C130.927946,-5.68137151e-15 156,25.072054 156,56 L156,62.1659169 C161.675427,63.1180731 166,68.0540074 166,74 L166,88 C166,94.0521096 161.51969,99.0577866 155.694861,99.8812385 C153.627775,119.687011 141.237365,136.422372 124,144.610951 Z" id="path-5"></path>
                    </defs>
      <g id="Avatar" stroke-width="1" fill-rule="evenodd">
                        <g id="Body" transform="translate(32.000000, 36.000000)">
                        <mask id="mask-6" fill="white">
                            <use xlink:href="#path-5"></use>
                        </mask>
                        <use fill="#D0C6AC" xlink:href="#path-5"></use>
        ${skinSVG}
        <path d="M156,79 L156,102 C156,132.927946 130.927946,158 100,158 C69.072054,158 44,132.927946 44,102 L44,79 L44,94 C44,124.927946 69.072054,150 100,150 C130.927946,150 156,124.927946 156,94 L156,79 Z" id="Neck-Shadow" opacity="0.100000001" fill="#000000" mask="url(#mask-6)"></path></g>
        ${clothesSVG}
        <g id="Face" transform="translate(76.000000, 82.000000)" fill="#000000">
        ${mouthSVG}
        ${facialHairSvg}
        ${noseSvg}
        ${eyesSVG}
        ${eyeBrowSvg}
        ${accessoriesTypeSvg}
        </g>
        ${hairSvg}
      </g>
    </svg>
  `;

    return (
        <div
            dangerouslySetInnerHTML={{ __html: avatarSVG }}
        />
    );
};

const AvatarBuilder = ({ properties }) => {
    const actualProperties = properties || AvatarProperties.defaultProperties;

    return (
        <div>
            <Avatar properties={actualProperties} />
        </div>
    );
};

export default AvatarBuilder;
