import  axiosClient  from "../axiosClient";



export function getSavedVideos(data){
    let bearerTokenHeader = localStorage.getItem('token');
    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${bearerTokenHeader}`;
    return axiosClient.get('/bookmarked',JSON.stringify(data));
}

export function getSavedVideosByCategory(data){
    let bearerTokenHeader = localStorage.getItem('token');
    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${bearerTokenHeader}`;
    return axiosClient.post('/bookmarked/category',JSON.stringify(data));
}

export function watchSavedVideos(data){
    let bearerTokenHeader = localStorage.getItem('token');
    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${bearerTokenHeader}`;
    return axiosClient.post('/play-saved',JSON.stringify(data));
}


export function SharedVideos(data){
    let bearerTokenHeader = localStorage.getItem('token');
    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${bearerTokenHeader}`;
    return axiosClient.post('/share/video',JSON.stringify(data));
}