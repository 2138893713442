import axiosClient from "../axiosClient";

const setBearerToken = () => {
  let bearerTokenHeader = localStorage.getItem('token');
  axiosClient.defaults.headers.common['Authorization'] = `Bearer ${bearerTokenHeader}`;
};

export function getRewards(data) {
  setBearerToken();
  return axiosClient.post('/rewards', JSON.stringify(data));
}

export function getSpinStatus() {
  setBearerToken();
  return axiosClient.post('/spin-wheel/status');
}

export function doSpin(coins) {
  setBearerToken();
  return axiosClient.post('/spin-wheel/spin', null, { params: { coins } });
}

export function doSpinAgain(coins, spinAgainId) {
  setBearerToken();
  return axiosClient.post('/spin-wheel/spin', null, { params: { coins, spin_id: spinAgainId } });
}

export function redeemSpin(coins) {
  setBearerToken();
  return axiosClient.post('/spin-wheel/redeem-spin', null, { params: { coins } });
}

export function getUserCoins() {
  setBearerToken();
  return axiosClient.get('/coins');
}

export function getStreaksInfo() {
  setBearerToken();
  return axiosClient.post('/streaks-info');
}

export function redeemStreaks() {
  setBearerToken();
  return axiosClient.post('/streaks-redeem');
}

export function registerStreak() {
  setBearerToken();
  return axiosClient.post('/streaks-register');
}

export function getLeaderboard () {
  setBearerToken();
  return axiosClient.post('/leaderboard')
}