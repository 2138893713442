import React from 'react';
import logoAnim from "../../animations/coinsBlast.webm";
import styles from "../../styles/animations_styles/logo_animation.module.css";
const CoinsBlast = () => {
    return (
        <>

            <video className={styles.coinBlastReward} preload="metadata" playsInline autoPlay muted controlsList='nodownload'>
                <source src={logoAnim} />
            </video>

        </>
    )
}
export default React.memo(CoinsBlast);
