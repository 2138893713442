import React from 'react';

import shareChallenge from '../../../images/v1/rewards/shareChallenge.svg'

const ShareAndGet = () => {
  return (
    <div className="share-and-get">
      <div className="blur blur-1"></div>
      <div className="blur blur-2"></div>
      <div className="blur blur-3"></div>
      <div className="content">
        <div className="text">
          <h2 className='stroke-color-black'>Share & Get</h2>
          <p className='stroke-color-black'>Share videos with 5 friends and get exciting rewards</p>
        </div>
        <div className="image">
          <img src={shareChallenge} alt="Gift wrap" />
        </div>
      </div>
      <style jsx>{`
        .share-and-get {
          background-color: #FFFC48;
          border-radius: 15px;
          padding: 20px;
          position: relative;
          overflow: hidden;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        }

        .blur {
          position: absolute;
          filter: blur(30px);
          opacity: 0.3;
        }

        .blur-1 {
          background-color: #D36D33;
          width: 100px;
          height: 100%;
          top: 0;
          left: -50px;
        }

        .blur-2 {
          background-color: #D92000;
          width: 100%;
          height: 60px;
          bottom: -30px;
          left: 0;
        }

        .blur-3 {
          background-color: #FE8500;
          width: 60px;
          height: 100%;
          top: 0;
          right: -30px;
        }

        .content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
          z-index: 1;
        }

        .text {
          flex: 1;
        }
      `}</style>
    </div>
  );
};

export default ShareAndGet;